<template>
  <van-popover
    v-model="showPopover"
    trigger="click"
    @close="close"
    :actions="option">
    <template v-for="item in option2">
      <div class="van-popover__action">
        <van-checkbox class="van-popover__action-text van-hairline--bottom" v-model="item.checked" shape="square"
                      checked-color="#151D36">{{item.text}}
        </van-checkbox>
      </div>
    </template>
    <div class="van-popover__action">
      <a class="btn van-popover__action-text" href="javascript: void (0)" @click="select">搜索</a>
    </div>


    <template #reference>
             <span class="popover-title">
                  <span>{{value}}</span>
                  <van-icon name="arrow-down" :class="showPopover && 'show'"/>
             </span>
    </template>
  </van-popover>
</template>

<script>
  export default {
    name: "vSelect",
    props: {
      option: Array,
      title: String,
      type: String,
      checked: Array,
      defaultValue: String
    },
    data() {
      return {
        showPopover: false,
        value: '',
        option2: [],
        result: []
      }
    },
    created() {
      this.value = this.title;
      this.result = this.checked || [];
      this.init();
    },
    watch: {
      option2: {
        deep: true,
        handler(val) {
          let temp = val.filter(item => item.checked);
          this.value = temp.length > 0 ? '已筛选' : this.title || '请筛选';
        }
      },

      defaultValue: {
        handler(val) {
          setTimeout(() => {
            const arr = val && val.length ? val.split(',') : [];
            for (const item of this.option2) {
              item.checked = arr.includes(item.value);
            }
          })
        },
        immediate: true
      }
    },

    methods: {

      close() {
        // this.reset(this.result);
      },

      init() {
        this.option.forEach(item => {
          let one = {
            text: item.text,
            value: item.value,
            checked: false
          };
          if (this.result.indexOf(item.value) !== -1) {
            one.checked = true;
          }
          this.option2.push(one);
        })
      },

      reset(checked) {
        this.option2 = [];
        this.option.forEach(item => {
          let one = {
            text: item.text,
            value: item.value,
            checked: false
          };
          if (checked.indexOf(item.value) !== -1) {
            one.checked = true;
          }
          this.option2.push(one);
        })
      },

      parentReset() {
        this.option2 = [];
        this.option.forEach(item => {
          let one = {
            text: item.text,
            value: item.value,
            checked: false
          };

          this.option2.push(one);
        })
      },


      select() {
        this.showPopover = false;
        let result = [];
        this.option2.forEach(item => {
          if (item.checked) {
            result.push(item.value);
          }
        });
        if (result.length) {
          this.result = result;
          this.$emit('change', result.join(","));
        } else {
          this.result = [];
          this.$emit('change', '');
        }

      }
    }
  }
</script>

<style scoped lang="less">
  .popover-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebed;
    border-radius: 11px;
    padding: 0 32px 0 10px;
    height: 25px;
    position: relative;

    span {
      font-size: 12px;
    }

    i {
      position: absolute;
      right: 11px;
      top: 6px;
      transition: all .3s ease;
      font-size: 12px;

      &.show {
        transform: rotate(180deg);
      }
    }
  }

  .btn {
    background: var(--text-color);
    color: #fff;
    border-radius: 4px;
    height: 25px;
  }
</style>
