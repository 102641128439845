<template>
  <div class="resource-page">
    <div class="resource-page-holder"></div>
    <v-header :title="currTab==='needs'?'群内需求':'群内商机'">
      <div class="back" slot="back" @click="$refs.commonShareSheet.show()">
        <i class="iconfont icon-share" style="transform: rotate(0)"></i>
      </div>
      <div class="home" slot="home" @click="visibles.publish = true">
        <van-icon name="plus"/>
      </div>
    </v-header>
    <van-tabs v-model="currTab">
      <van-tab title="群内需求" name="needs"
               style="background: #f4f4f4;padding-bottom: 20px;">
        <div class="filters" style="padding-left: 27px">
          <v-popover
            :option="needsTypeOptions" title="需要什么"
            :defaultValue="query.needs.needsTypes" ref="popover"
            @change="setNeedsQuery('needsTypes',$event)"></v-popover>
          <v-select :option="rewardTypeOptions" :defaultValue="query.needs.needsType"
                    @change="setNeedsQuery('needsType',$event.value)"></v-select>
          <v-select :option="needsDaysOptions" :defaultValue="query.needs.inDaysAfterCreate"
                    @change="setNeedsQuery('inDaysAfterCreate',$event.value)"></v-select>
        </div>
        <div :style="{height: styles.scrollHeight}" style="overflow: scroll">
          <div class="banner" @click="$router.push('/resource/publish?tab=needs')">
            <img src="https://cdn.001ppt.cn/temp/banner_needs.png" alt="">
          </div>
          <van-search shape="round" placeholder="搜索关键词试一试" v-model="query.needs.keyword" @search="loadNeeds(true)"/>

          <van-pull-refresh v-model="needs.refreshing" @refresh="loadNeeds(true)">
            <van-list offset="400"
                      :immediate-check="true"
                      :finished="!needs.hasMore"
                      finished-text="灵感到底了~"
                      v-model="needs.loading"
                      @load="loadNeeds(false)">
              <div class="case-container">
                <div class="item" v-for="(item,index) in needs.records" :key="index">
                  <needs-card :item="item" show-collect/>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>


      <van-tab title="群内资源" name="bizResource"
               style="background: #f4f4f4;padding-bottom: 20px;">
        <div class="filters" style="padding-left: 27px">
          <v-popover
            :option="resourceTypeOptions" title="提供什么"
            :defaultValue="query.bizResource.resourceTypes" ref="popover"
            @change="setBizResourceQuery('needsTypes',$event)"></v-popover>
          <v-select :option="resourceNeedsTypeOptions" :defaultValue="query.bizResource.resourceType"
                    @change="setBizResourceQuery('needsType',$event.value)"></v-select>
          <v-select :option="needsDaysOptions" :defaultValue="query.bizResource.inDaysAfterCreate"
                    @change="setBizResourceQuery('inDaysAfterCreate',$event.value)"></v-select>
        </div>
        <div :style="{height: styles.scrollHeight}" style="overflow: scroll">
          <div class="banner" @click="$router.push('/resource/publish?tab=bizResource')">
            <img src="https://cdn.001ppt.cn/temp/banner_resource.png" alt="">
          </div>
          <van-search shape="round" placeholder="搜索关键词试一试" v-model="query.bizResource.keyword"
                      @search="loadBizResources(true)"/>

          <van-pull-refresh v-model="bizResource.refreshing" @refresh="loadBizResources(true)">
            <van-list offset="400"
                      :immediate-check="true"
                      :finished="!bizResource.hasMore"
                      finished-text="灵感到底了~"
                      v-model="bizResource.loading"
                      @load="loadBizResources(false)">
              <div class="case-container">
                <div class="item" v-for="(item,index) in bizResource.records" :key="index">
                  <biz-resource-card :item="item" show-collect/>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>


    <van-popup v-model="visibles.publish" position="bottom" round closeable>
      <div class="publish">
        <div class="publish-title">发布商机</div>
        <div class="publish-desc">点击下列按钮可选择发布资源或需求~</div>
        <div class="publish-panel">
          <router-link to="/resource/publish?tab=needs"
                       class="publish-btn">
            <div class="publish-icon">需</div>
            <div class="publish-text">发布需求</div>
          </router-link>
          <router-link to="/resource/publish?tab=bizResource"
                       class="publish-btn">
            <div class="publish-icon">资</div>
            <div class="publish-text">发布资源</div>
          </router-link>
        </div>
      </div>
    </van-popup>


    <gzh-dialog ref="gzhDialog" :type="2"/>
    <div class="publish-record" @click="$router.push('/publish')">
      <img src="@/assets/svg/publish.svg" alt="">
    </div>
    <div class="go-wx-group" @click="$router.push('/wxGroup')">
      <img src="https://cdn.001ppt.cn/wxApp/assets/svg/chat_group.svg"/>
    </div>
    <div class="collect-btn" @click="$router.push('/resource/collect')">
      <div class="collect-count" v-if="currTab==='bizResource' && resourceCollectCount>0">{{ resourceCollectCount }}</div>
      <div class="collect-count" v-else-if="needsCollectCount>0 &&currTab === 'needs'">{{ needsCollectCount }}</div>
      <img src="@/assets/svg/collection.svg" style="height: 18px;width: 18px;"/>
      <div>收藏夹</div>
    </div>
    <div class="yanruyu" @click="$refs.gzhDialog.show()">
      <img src="@/assets/svg/customer-service.svg"/>
    </div>
    <common-share-sheet ref="commonShareSheet" :inner-copy="true" @share="onShare"/>
  </div>
</template>

<script>
import {options} from "./options";
import vSelect from '@/components/select';
import vPopover from '@/components/popover'
import {needsV2Api} from "../../api/v2/needsV2Api";
import NeedsCard from "../../components/biz/NeedsCard";
import BizResourceCard from "../../components/biz/BizResourceCard";
import {bizResourceV2Api} from "../../api/v2/bizResourceV2Api";
import {sleep} from "../../config/util";
import GzhDialog from "../../components/GzhDialog";
import CommonShareSheet from "../../components/commonShareSheet";

export default {
  components: {CommonShareSheet, GzhDialog, vSelect, vPopover, NeedsCard, BizResourceCard},

  data() {
    return {
      ...options,
      currTab: 'needs',
      visibles: {
        publish: false
      },
      styles: {
        scrollHeight: '100vh'
      },
      query: {
        needs: {
          needsType: null,
          needsTypes: [],
          inDaysAfterCreate: null,
          keyword: ''
        },
        bizResource: {
          resourceType: null,
          resourceTypes: [],
          inDaysAfterCreate: null,
          keyword: ''
        },
      },

      needs: {
        current: 0,
        size: 10,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
      },

      bizResource: {
        current: 0,
        size: 10,
        records: [],
        refreshing: false,
        loading: false,
        hasMore: true,
      },
      needsCollectCount: 0,
      resourceCollectCount: 0
    }
  },


  mounted() {
    this.initStyles()
    this.loadCollectCount()
  },


  methods: {

    async loadCollectCount() {
      this.needsCollectCount = await needsV2Api.findMyCollectCount();
      this.resourceCollectCount = await bizResourceV2Api.findMyCollectCount();
    },

    async initStyles() {
      while (true) {
        try {
          const $headerRect = document.querySelector('.resource-page-holder').getBoundingClientRect()
          const $tabRect = document.querySelector('.van-tabs__wrap').getBoundingClientRect()
          const $filtersRect = document.querySelector('.filters').getBoundingClientRect()
          const $tabBarRect = document.querySelector('.tabbar-menu').getBoundingClientRect()
          const totalHeight = $headerRect.height + $tabRect.height + $filtersRect.height + $tabBarRect.height
          this.styles.scrollHeight = `calc(100vh - ${totalHeight}px)`
          break
        } catch (e) {
          // console.error(e)
          await sleep(50)
        }
      }
    },


    setNeedsQuery(key, value) {
      this.query.needs[key] = value
      this.loadNeeds(true)
    },


    setBizResourceQuery(key, value) {
      this.query.bizResource[key] = value
      this.loadBizResources(true)
    },


    async loadNeeds(isRefresh) {
      const {needs} = this
      if (!isRefresh && !needs.hasMore) {
        return;
      }
      if (isRefresh) {
        this.needs.refreshing = true
      }

      try {
        this.needs.loading = true
        const current = isRefresh ? 1 : needs.current + 1;
        const size = needs.size;
        const res = await needsV2Api.search({current, size, ...this.query.needs});
        const hasMore = res.current * res.pageSize < res.total;
        for (const item of res.records) {
          item.displayMobile = item.mobile ? item.mobile.slice(0, 3) + '****' + item.mobile.slice(7) : '';
          item.displayWxNum = item.wxNum.slice(0, 2) + '****';
          item.imgUrlArr = JSON.parse(item.imgUrls || '[]');
          item.expireDate = item.expireDate.slice(0, 10);
        }
        this.needs.current = res.current
        this.needs.size = res.size || res.pageSize
        this.needs.hasMore = hasMore

        if (isRefresh) {
          this.needs.records = res.records
        } else {
          this.needs.records.push(...res.records)
        }
      } finally {
        this.needs.refreshing = false
        this.needs.loading = false
      }
    },


    async loadBizResources(isRefresh) {
      const {bizResource} = this
      if (!isRefresh && !bizResource.hasMore) {
        return;
      }
      if (isRefresh) {
        this.bizResource.refreshing = true
      }

      try {
        this.bizResource.loading = true
        const current = isRefresh ? 1 : bizResource.current + 1;
        const size = bizResource.size;
        const res = await bizResourceV2Api.search({current, size, ...this.query.bizResource});
        const hasMore = res.current * res.pageSize < res.total;
        for (const item of res.records) {
          item.displayMobile = item.mobile ? item.mobile.slice(0, 3) + '****' + item.mobile.slice(7) : '';
          item.displayWxNum = item.wxNum.slice(0, 2) + '****';
          item.imgUrlArr = JSON.parse(item.imgUrls || '[]');
          item.expireDate = item.expireDate.slice(0, 10);
        }
        this.bizResource.current = res.current
        this.bizResource.size = res.size || res.pageSize
        this.bizResource.hasMore = hasMore

        if (isRefresh) {
          this.bizResource.records = res.records
        } else {
          this.bizResource.records.push(...res.records)
        }
      } finally {
        this.bizResource.refreshing = false
        this.bizResource.loading = false
      }
    },


    onShare(code){
      this.$refs.commonShareSheet.hide()
      switch (code){
        case 'sharePost':
          const token = localStorage.getItem('token')
          this.$store.commit('previewSingleImage/show',{
            src: `https://api-wx.001ppt.cn/api/applet/v2/resource/home/sharePost.jpg?deviceWidth=${document.body.clientWidth}&Token=${token}`
          })
          break
      }
    }

  },


}
</script>

<style scoped lang="less">
.resource-page {
  height: 100vh;
  overflow: hidden;
}

.resource-page-holder {
  height: 40px;
}


.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 27px 12px 12px;
}


.publish-title {
  font-size: 14px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
}

.publish-desc {
  font-size: 12px;
  padding: 0 0 12px 0;
  display: flex;
  justify-content: center;
  color: #999;
}

.publish-panel {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 24px;
  width: 100%;
}

.publish-btn {
  margin: 8px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.publish-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  background: #e44c4c;
  color: #FFF;
  font-size: 16px;
  font-weight: bolder;
  border-radius: 100px;
}

.publish-text {
  font-size: 14px;
  margin-top: 8px;
}

.banner {
  padding: 0 28px 8px 28px;
  height: calc((100vw - 56px) * 475 / 1258);
}

.banner img {
  border-radius: 6px;
  width: 100%;
  height: calc((100vw - 56px) * 475 / 1258);
}

.publish-record{
  position: fixed;
  right: 8px;
  bottom: 258px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  img{
    height: 25px;
    width: 25px;
  }
}

.go-wx-group {
  position: fixed;
  right: 8px;
  bottom: 205px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
}

.go-wx-group img {
  height: 30px;
  width: 30px;
}

.yanruyu {
  position: fixed;
  bottom: 100px;
  right: 8px;
  background: #fff;
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
}

.yanruyu img {
  height: 25px;
  width: 25px;
  border-radius: 25px;
}


.collect-count {
  position: absolute;
  height: 15px;
  width: 15px;
  background: #e1494e;
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  right: 0;
  top: 0;
}


.collect-btn {
  position: fixed;
  bottom: 152px;
  right: 8px;
  background: #fff;
  height: 48px;
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
}


</style>
<style lang="less">
.resource-page .van-search {
  padding: 12px 27px;
}
</style>
